.sidebarColor {
  background: #01338c;
}

.onHover {
  /* background-color: white; */
  transition: background-color 0.3s ease-in-out;
  padding: 10px;
}

.onHover:hover {
  background-color: #2e79ff;
}

.miniMize {
  display: flex;
}

.nameSidebar {
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 0.5rem;
}
