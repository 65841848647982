table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.styled-table thead tr {
  text-align: left;
}

.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  background-color: #e1eaf6;
}

th {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* th {
  background-color: #F8FBFF;
  position: relative;
  cursor: pointer;  
} */
