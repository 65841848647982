.body {
  margin-left: 2rem;
  padding: 1rem;
}

.bodys {
  margin-left: 1rem;
  padding: 0.75em;
}

.bodyTextH3 {
  color: #000;
  font-weight: "500";
}

.bodyTextH1 {
  color: #01338c;
  font-weight: bold;
  font-size: 1.2rem;
}

.bodyTextH3Mobile {
  color: #000;
  font-size: 12px;
}

.bodyTextH1Mobile {
  color: #01338c;
  font-weight: bold;
  font-size: 12px;
}
